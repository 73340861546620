import { useParams } from "react-router-dom"
import ChangePassword from "../ChangePassword"
import { CognitoUser } from "amazon-cognito-identity-js"
import getPool from '../../UserPool';
import { DEFAULT_CLIENT_ID, DEFAULT_USER_POOL_ID } from '../../shared/Config';
import { useState } from "react";
import { RecoveryPasswordService } from "../../services/RecoveryPasswordService";
import { Form } from "react-bootstrap";
import style from '../../components/Form.module.scss';


function RecoveryEmailPassword({ email, recoveryEmail }: { email: any, recoveryEmail: any }) {
    const [stage, setStage] = useState(1); // 1 = set email options stage, 2 = set code stage // 3 = set recovery code stage
    const [valueChecked, setValueChecked] = useState('')

    const handleValueChange = (event: any) => {
        setValueChecked(event.target.value)
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = Object.fromEntries(urlParams.entries());
    let Pool: any = null
    const routeParams = useParams()

    if (params.client_id) {
        Pool = getPool(routeParams.pool_id || "", params.client_id)
    } else {
        Pool = getPool(DEFAULT_USER_POOL_ID, DEFAULT_CLIENT_ID) // default redirect without params
    }
    const getUser = () => {
        return new CognitoUser({
            Username: email.toLowerCase(),
            Pool
        });
    };
    const postRecovery = () => {
        return RecoveryPasswordService.sendCodeRecoveryEmail({ email: email, poolId: Pool.userPoolId })
    }

    const sendEmailCode = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        if (valueChecked === 'recoveryEmail') {
            postRecovery()
            setStage(3)
        } else if (valueChecked === 'email') {
            getUser().forgotPassword({
                onSuccess: (_: any) => {
                },
                onFailure: (_: any) => {
                },
                inputVerificationCode: (_: any) => {
                    setStage(2);
                }
            });
        }
    }

    return (
        <>
            {stage === 1 && (
                <>
                    <div style={{ padding: '1.5em 3em 0em' }}>
                        <p style={{ textAlign: 'center', lineHeight: '30px' }}>
                            Selecione um e-mail para
                            encaminhar o código de recuperação.
                        </p>
                    </div>
                    <Form onSubmit={sendEmailCode}
                        method="post"
                        className='col-12'
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}
                    >
                        <Form.Group>
                            <Form.Check
                                type='radio'
                                className="mb-3"
                                id='radioCheck'
                                label={`Enviar para ${email}`}
                                value='email'
                                checked={valueChecked === 'email'}
                                onChange={handleValueChange}
                            />
                            <Form.Check
                                type='radio'
                                className="mb-3"
                                id='radio-recoveryemail'
                                label={`Enviar para ${recoveryEmail}`}
                                value='recoveryEmail'
                                checked={valueChecked === 'recoveryEmail'}
                                onChange={handleValueChange}
                            />
                        </Form.Group>
                        <div className='d-flex justify-content-center mb-4 px-5'>
                            <button name='btnSubmit'
                                className={style.submitButton}
                                id="submitButton"
                                type="submit">
                                Enviar
                            </button>
                        </div>
                    </Form>
                </>
            )}
            {stage === 2 && (
                <>
                    <ChangePassword method={2} email={email} />
                </>
            )}
            {stage === 3 && (
                <>
                    <ChangePassword method={3} email={email} />
                </>
            )}
        </>
    )
}

export default RecoveryEmailPassword