import axios from "axios"
import { REACT_APP_API_MANAGEMENT_URL } from "../shared/Config"

export const RecoveryPasswordService = {
    getRecoveryEmail: async function (urlParams: any) {
        const response = await axios.get(`${REACT_APP_API_MANAGEMENT_URL}/user/password/recovery/poolid/${urlParams.poolId}/email/${urlParams.email}`)

        return response
    },

    sendCodeRecoveryEmail: async function (urlParams: any) {
        const response = await axios.post(`${REACT_APP_API_MANAGEMENT_URL}/user/password/recovery/recoveryemail/poolid/${urlParams.poolId}/email/${urlParams.email}`)

        return response
    },

    confirmPasswordRecovery: async function (codeVerification: string, email: string, poolId: string, newPassword: string, callbacks: {
        onSuccess: (success: string) => void;
        onFailure: (err: Error) => void;
    }) {
        try {
            const response = await axios.put(`${REACT_APP_API_MANAGEMENT_URL}/user/password/recovery/adminpassword`, { codeVerification, email, poolId, newPassword })

            callbacks.onSuccess(response.data);

            return response;
        } catch (err) {
            callbacks.onFailure(new Error);

            throw err;
        }
    }
}