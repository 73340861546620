import style from '../../components/Form.module.scss';
import Form from 'react-bootstrap/Form';
import PasswordInput from '../../components/PasswordInput';
import OnKeyUp from '../../components/OnKeyUp';
import Col from 'react-bootstrap/Col';
import { useEffect, useContext } from 'react';
import { useState } from "react";

import { AccountContext } from '../../components/Accounts';
import Warnings from '../../components/Warning';
import { navigateToDefaultPage, navigateToDefaultRedirect, navigateToPage } from '../../services/navigateService';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { PageEnum } from '../../enums/pageEnum';
import { Row } from 'react-bootstrap';

function Login(this: any) {
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [isLoadingParams, setisLoadingParams] = useState(true);


    const navigate = useNavigate();
    const urlparams = useParams();
    const queryString = window.location.search;
    const urlQuery = new URLSearchParams(queryString);
    const params = Object.fromEntries(urlQuery.entries());
    const [email, setEmail] = useState(params.login_hint || "");

    function forgotPassword() {
        return navigateToPage(navigate, urlparams, PageEnum.RESET_PASSWORD, null, true)
    }

    function qrcodeLogin() {
        return navigateToPage(navigate, urlparams, PageEnum.QRCODE_LOGIN, null, true)
    }

    const [onKey, setOnKey] = useState(<></>);

    const { authenticate, getSession } = useContext(AccountContext);

    useEffect(() => {
        if ((!params.client_id || !params.redirect_uri)) {
            navigateToDefaultRedirect(urlparams)
            return
        }

        if (isLoadingParams) {
            setisLoadingParams(false)
            return
        }
    }, []);

    useEffect(() => {
        const key = document.getElementById('formPassword');
        if (key) {
            key.addEventListener("keyup", function (event) {
                if (event.getModifierState("CapsLock")) {
                    setOnKey(<div className={style.warnKey} >
                        <OnKeyUp />
                    </div>
                    )
                } else {
                    setOnKey(<div></div>)
                }
            });
        }
    }, [password])

    function handleChange(e: any) {
        setPassword(e.target.value);
    }

    function handleSubmit(event: any) {
        event.preventDefault();

        setError(false);
        setisLoading(true);

        //TODO Validate if user is already logged in

        authenticate(email, password, urlparams.pool_id, params.client_id, urlparams)
            .then(() => { })
            .catch((error: any) => {
                setError(true);

                if (error.message === "Password attempts exceeded") {
                    return setErrorMessage("Número de tentativas excedido!");
                }

                setErrorMessage("Email ou senha incorretos!");
            })
            .finally(() => {
                setisLoading(false);
            })
    }

    if (isLoadingParams) {
        return <></>
    }

    return (
        <>
            <Col>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="my-3 mx-3 my-sm-4 mx-sm-4 my-md-4 mx-md-5" controlId="formGroupEmail">
                        <Form.Label>E-mail</Form.Label>
                        <input value={email} onChange={event => setEmail(event.target.value)}
                            className={`${style.password} ${error ? style.fieldError : style.fieldSuccess}`}
                            style={{ width: '100%' }} type="email" required />
                    </Form.Group>
                    <Form.Group className="my-3 mx-3 my-sm-4 mx-sm-4 my-md-4 mx-md-5" controlId="formGroupPassword" id="formPassword">
                        <Form.Label>Senha</Form.Label>
                        <Row>
                            <Col sm={12}>
                                <PasswordInput errors={error} id={"password"} value={password} handleChange={handleChange} />
                            </Col>
                        </Row>
                        {onKey}

                        <a onClick={forgotPassword} className='d-flex justify-content-end mt-3'>
                            Esqueci minha senha
                        </a>
                        <a onClick={qrcodeLogin} className='d-flex justify-content-end mt-2'>
                            Login com código QR
                        </a>
                        <div style={{ marginBottom: '-1em' }}>
                            {error ? <Warnings warning={errorMessage} type="error" /> : <></>}
                        </div>
                    </Form.Group>
                    <div className='d-flex justify-content-center'>
                        <button disabled={isLoading} className={style.submitButton} type="submit" id="submitButton">
                            Entrar
                        </button>
                    </div>

                </Form>
            </Col>
        </>
    )
}

export default Login;