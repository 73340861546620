import style from '../../components/Form.module.scss';
import Col from 'react-bootstrap/Col';
import { useContext, useEffect, useState } from "react";

import { generateRedirectUri, navigateToPage, navigateToRedirectUri } from '../../services/navigateService';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { PageEnum } from '../../enums/pageEnum';
import { Row } from 'react-bootstrap';
import { AccountContext } from '../../components/Accounts';
import { FaUser } from 'react-icons/fa';
import { DEFAULT_COUNTDOWN_TIME } from '../../shared/Config';
import { AuthorizationService } from '../../services/AuthorizeService';

let stopped = false
let time = parseInt(DEFAULT_COUNTDOWN_TIME)

export default function SwitchAccount() {
    const navigate = useNavigate();
    const urlparams = useParams();
    const queryString = window.location.search;
    const urlQuery = new URLSearchParams(queryString);
    const params = Object.fromEntries(urlQuery.entries());

    const [counter, setCounter] = useState(time)
    const [showAllUsers, setShowAllUsers] = useState(false);

    const { getInfoUser } = useContext(AccountContext);
    const user = getInfoUser();

    const users = Array.isArray(user) ? user : [user];
    const uniqueUsers = Array.from(new Map(users.map(u => [u.email, u])).values());
    const usersToDisplay = uniqueUsers
        .filter(({ email, name }) => email && name)
        .reverse()
        .slice(0, showAllUsers ? undefined : 5);

    useEffect(() => {
        counter > 0 && setTimeout(() => {
            setCounter(counter - 1)
        }, 1000)
    })

    let countdownSession = null

    if (!countdownSession && usersToDisplay.length <= 1) {
        countdownSession = setTimeout(handleContinueSession, time * 1000)
    }

    function handleContinueSession(user: any) {
        if (!stopped) {
            if (params && params.redirect_uri) {
                const urlString = new URL(params.redirect_uri)

                if (urlString.protocol !== 'http:' && urlString.protocol !== 'https:') {
                    let redirect_uri = generateRedirectUri(params)
                    return navigateToPage(navigate, urlQuery, PageEnum.LOGGED_IN, { redirect_uri }, false, urlparams)
                }

                return navigateToRedirectUri(params)
            }
        }

        if (usersToDisplay.length > 1) {
            const code = sessionStorage.getItem('codeChallenge');
            const method = sessionStorage.getItem('codeMethod');
            const { authorization_code, email, name, picture, session, success, ...rest } = params;
            const newParams = {
                ...rest,
                code_challenge: code,
                code_challenge_method: method,
                response_type: "code"
            };

            AuthorizationService.getNewAuthorization(newParams, urlparams, user.session).then((response: any) => {
                let data = response.data;

                if (data && data.redirect_uri) {
                    const urlString = new URL(data.redirect_uri)

                    if (urlString.protocol !== 'http:' && urlString.protocol !== 'https:') {
                        let redirect_uri = generateRedirectUri(data)

                        return navigateToPage(navigate, urlQuery, PageEnum.LOGGED_IN, { redirect_uri }, false, urlparams)
                    }

                    return navigateToRedirectUri(data)
                }

            })
        }
    }

    function handleReturnLoginPage() {
        stopped = true
        return navigateToPage(navigate, urlparams, PageEnum.LOGIN, params)
    }

    if (usersToDisplay.length > 1) {
        stopped = true
    }

    return (
        <>
            <Col sm={12}>
                <p className='d-flex justify-content-center'>Escolha o usuário:</p>
                <Row className='d-flex align-items-center justify-content-center mt-5' style={{ gap: '0.5rem' }}>
                    {usersToDisplay.map((user, index) => (
                        <div key={index} className={`${style.btnSession}`} onClick={() => handleContinueSession(user)}>
                            {user.picture ? (
                                <img src={user.picture} alt="" />
                            ) : (
                                <FaUser />
                            )}
                            <div>
                                <div className={`${style.name} ${style.btnSessionEllipsis}`}>{user.name}</div>
                                <div className={`${style.email} ${style.btnSessionEllipsis}`}>{user.email}</div>
                            </div>
                        </div>
                    ))}
                </Row>
                {users.length > 5 && !showAllUsers && (
                    <Col className='d-flex justify-content-center mt-3'>
                        <div className={`${style.btnSession} d-flex justify-content-center`} style={{ minHeight: "1.75rem" }} onClick={() => setShowAllUsers(true)}>
                            <div>
                                Mais
                            </div>
                        </div>
                    </Col>
                )}
                <Row className='d-flex align-items-center justify-content-center mt-3'>
                    <div className={style.btnSession} onClick={handleReturnLoginPage}>
                        <a className='d-flex align-items-center justify-content-center'>Usar outra conta</a>
                    </div>
                </Row>
            </Col>
            {usersToDisplay.length < 2 && (
                <Col className='d-flex justify-content-center'>
                    <div className='d-flex'>Você será redirecionado em {counter} segundos...</div>
                </Col>
            )}
        </>
    )
}