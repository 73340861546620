import { useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Account } from '../../components/Accounts';
import Layout from '../Layout';
import Login from '../Login';
import Authorization from '../Authorization';
import NewPassword from '../NewPassword';
import ResetPassword from '../ResetPassword';
import TotpAuth from '../TotpAuth';
import NotFound from '../NotFound';
import RootPage from '../HomeRootPage';
import UpdateAttributes from '../UpdateAttributes';
import LoggedIn from '../LoggedIn';
import SwitchAccount from '../SwitchAccount';
import QrCodeLogin from '../QrCodeLogin';


function App() {

  return (
    <Router>
      <Account>
        <Routes>
          <Route path="/" element={<RootPage />} />
          <Route path="/" element={<Layout />} >
            <Route path='*' element={<NotFound />} />
          </Route>
          <Route path='/:pool_id/:tenant' element={<Layout />} >
            <Route path='' element={<NotFound />} />
            <Route path='authorization' element={<Authorization />} />
            <Route index element={<Login />} />
            <Route path='new-password' element={<NewPassword />} />
            <Route path='reset' element={<ResetPassword />} />
            <Route path='totp' element={<TotpAuth />} />
            <Route path='update' element={<UpdateAttributes/>}/>
            <Route path='logged-in' element={<LoggedIn/>}/>
            <Route path='switch-account' element={<SwitchAccount/>}/>
            <Route path='qrcode-login' element={<QrCodeLogin/>}/>
          </Route>
        </Routes>
      </Account>
    </Router>
  );
}

export default App;
