import {Outlet, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import style from '../Home/App.module.scss';
import imgLogo from '../../assets/img/logo-mobilemed.png';
import { navigateToDefaultPage } from "../../services/navigateService";
import Layout from "../Layout";
import { useEffect } from "react";

const RootPage = () => {

  const navigate = useNavigate()

  useEffect(() => {
    navigateToDefaultPage(navigate)
  })

  return (
    <>
      <Layout />
    </>
  );
};

export default RootPage;