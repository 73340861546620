import { useEffect, useContext, useState } from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AccountContext } from "../../components/Accounts";
import { Form } from "react-bootstrap";
import style from '../../components/Form.module.scss';
import Warnings from "../../components/Warning";

import { navigateToPage } from "../../services/navigateService";
import { PageEnum } from "../../enums/pageEnum";
import { useNavigate, useParams } from 'react-router-dom';

export default () => {

    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [locale, setLocale] = useState("pt-br");
    const [phone_number, setPhone] = useState("");
    const [picture, setPicture] = useState("");

    const { getSession, getCognitoUser } = useContext(AccountContext);

    const user = getCognitoUser();


    const navigate = useNavigate();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const urlUseParams = useParams();


    useEffect(() => {
        if (!user) {
            return navigateToPage(navigate, urlUseParams, PageEnum.LOGIN, null, true)
        }

        getSession().then((data: any) => {
            var attributes = data.attributes
            setName(attributes["name"]);
            setGender(attributes["gender"]);
            setLocale(attributes["locale"]);
            setPhone(attributes["phone_number"]);
            setPicture(attributes["picture"]);
        }); // get user current Attributes

    }, []);

    const onSubmit = (event: any) => {
        event.preventDefault();

        const attributes = [
            new CognitoUserAttribute({ Name: "name", Value: name }),
            new CognitoUserAttribute({ Name: "gender", Value: gender }),
            new CognitoUserAttribute({ Name: "locale", Value: locale }),
            new CognitoUserAttribute({ Name: "phone_number", Value: phone_number }),
            new CognitoUserAttribute({ Name: "picture", Value: '.' }),
        ];

        user.updateAttributes(attributes, (err: any, result: any) => {
            return navigateToPage(navigate, urlParams, PageEnum.LOGIN, null, true)
        });
    };

    return (
        <>
            <div className="pt-2 px-5">
                <Warnings warning="Atualize seus dados de usuário" type="warning" />
            </div>
            <div id="formUpdate">
                <Form onSubmit={onSubmit}>
                    <div >
                        <img className={style.imgUser} src={picture} />
                    </div>
                    <Form.Group className="my-4 mx-5" controlId="formGroupName">
                        <Form.Label>Nome</Form.Label>
                        <input value={name} onChange={event => setName(event.target.value)}
                            className={style.password}
                            style={{ width: '100%' }} required />
                    </Form.Group>
                    <Form.Group className="my-4 mx-5" controlId="formGroupGender">
                        <Form.Label>Gênero</Form.Label>
                        <input value={gender} onChange={event => setGender(event.target.value)}
                            className={style.password}
                            style={{ width: '100%' }} required />
                    </Form.Group>
                    <Form.Group className="my-4 mx-5" controlId="formGroupPhone">
                        <Form.Label>Número de telefone</Form.Label>
                        <input value={phone_number} onChange={event => setPhone(event.target.value)}
                            className={style.password} pattern="^\+[1-9][0-9]{0,24}$"
                            title="Insira um número de telefone, incluindo + e o código do país"
                            style={{ width: '100%' }} required />
                    </Form.Group>
                    <Form.Group className="my-4 mx-5" controlId="formGroupLocale">
                        <Form.Label>Localização</Form.Label>
                        <select name="locale" id="locale" value={locale} onChange={event => setLocale(event.target.value)}
                            className={style.password} required
                        >
                            <option value="pt-br">pt-br</option>
                            <option value="en-us">en-us</option>
                        </select>
                    </Form.Group>
                    <div className='d-flex justify-content-center'>
                        <button className={style.submitButton} type="submit" id="submitButton">
                            Enviar
                        </button>
                    </div>
                </Form>
            </div>
        </>
    );
};
