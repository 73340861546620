import { useEffect } from "react";
import ChangePassword from "../ChangePassword";

export default () => { 

useEffect(() => {
  }, []);

  return (
    <div>
        <ChangePassword method={1} email={''}/>
    </div>
  );
};
