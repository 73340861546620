import { useEffect, useRef, useState } from 'react';
import BackToLogin from '../../components/BackToLogin';
import style from '../../components/Form.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { QrcodeService } from '../../services/QrcodeService';
import { generateRedirectUri, navigateToPage, navigateToRedirectUri } from '../../services/navigateService';
import { PageEnum } from '../../enums/pageEnum';
import { IndexedService } from '../../services/IndexedService';

export default function QrCodeLogin() {
    const navigate = useNavigate();
    const urlparams = useParams();
    const queryString = window.location.search;
    const urlQuery = new URLSearchParams(queryString);
    const params = Object.fromEntries(urlQuery.entries());
    const isActive = useRef(true);
    const isFetchActive = useRef(false);
    let [qrCodeLoading, setQrCodeLoading] = useState(false);
    let [qrcode, setQrcode] = useState('');
    let [idQrcode, setIdQrcode] = useState('');

    const fetchQrcode = async () => {
        if (isFetchActive.current)
            return

        isFetchActive.current = true
        const response = await QrcodeService.getQrcode(params, urlparams)

        setQrcode(response.data.qrcode)
        setIdQrcode(response.data.idQrcode)
        setQrCodeLoading(true)
    }

    const verifyQrcodeLogin = async () => {
        if (!idQrcode || !isActive.current)
            return

        try {
            const response = await QrcodeService.verifyLoginMethod(params, urlparams, idQrcode)
            if (response.data && urlparams.pool_id) {
                let data = response.data
                await IndexedService.setTransactionDB(urlparams.pool_id, data.sessionToken, data.email)
                if (params && params.redirect_uri) {
                    const urlString = new URL(params.redirect_uri)
                    if (urlString.protocol !== 'http:' && urlString.protocol !== 'https:') {
                        let redirect_uri = generateRedirectUri(params)

                        return navigateToPage(navigate, urlQuery, PageEnum.LOGGED_IN, { redirect_uri }, false, urlparams)
                    }

                    return navigateToRedirectUri(params)
                }
            } else {
                setTimeout(verifyQrcodeLogin, 2000)
            }
        } catch (error) {
            setTimeout(verifyQrcodeLogin, 2000)
        }
    }

    useEffect(() => {
        fetchQrcode()
        isActive.current = true

        const interval = setInterval(() => {
            isFetchActive.current = false
            fetchQrcode()
        }, 303000)

        return () => {
            clearInterval(interval)
            isActive.current = false
        }
    }, [])

    useEffect(() => {
        verifyQrcodeLogin()
    }, [idQrcode])

    return (
        <>
            <div className='container d-flex flex-column align-items-center text-center col-12;'>
                <p style={{ lineHeight: '30px' }}>
                    Escaneie o código QR em nosso aplicativo:
                </p>
                <p>
                    {
                        qrCodeLoading ?
                            <img style={{ maxWidth: '60%' }} src={qrcode} /> :
                            <div className="spinner-border spinner-border-sm" role="status" />
                    }
                </p>
            </div>

            <div className={style.return} style={{ padding: '0em 0em 0em 1.5em' }}>
                <BackToLogin message='Voltar ao Login' url='/' />
            </div>
        </>
    )
}