import { Form } from 'react-bootstrap';
import style from '../../components/Form.module.scss';
import { useState } from "react";
import Warnings from '../../components/Warning';
import BackToLogin from '../../components/BackToLogin';
import { useContext, useEffect } from "react";
import { AccountContext } from '../../components/Accounts';
import { useNavigate, useParams } from 'react-router-dom';
import { navigateToPage, navigateToRedirectUri } from '../../services/navigateService';
import { PageEnum } from '../../enums/pageEnum';

function TotpAuth() {

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [totpCode, setTotpCode] = useState("");
    const urlparams = useParams();
    const queryString = window.location.search;
    const urlQuery = new URLSearchParams(queryString);
    const params = Object.fromEntries(urlQuery.entries());

    const { getCognitoUser, totpAuth } = useContext(AccountContext);

    const navigate = useNavigate()
    useEffect(() => {
        const user = getCognitoUser();

        if (!user) {
            return navigateToPage(navigate, urlparams, PageEnum.LOGIN, null, true)
        }
    })

    const delay = (ms: number | undefined) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    function handleSubmit(event: any) {
        event.preventDefault();
        setError(false);
        setisLoading(true);

        totpAuth(totpCode, urlparams)
            .then(() => { })
            .catch(async (error: any) => {
                setError(true)

                if (error.message.includes("Too many invalid credentials attempts")) {
                    return setErrorMessage("Número de tentativas excedido! Volte ao Login e tente novamente.");
                }
                if (error.message.includes("expired")) {
                    setErrorMessage("Sessão expirada! Redirecionando...");
                    await delay(1000);
                    return navigateToRedirectUri({
                        error: "time_expired",
                        error_description: "Time expired, please try again",
                        redirect_uri: params.redirect_uri,
                    })
                }
                setErrorMessage("Código inválido!")
            })
            .finally(() => {
                setisLoading(false);
            })
    }

    function handleChangeTotp(event: any) {
        setTotpCode(event.target.value);
    }

    return (
        <div className={style.ConfigStyle}>
            <div style={{ margin: '1.5em 2em 1.5em', textAlign: 'center', lineHeight: '30px' }}>
                <span>
                    Digite o código de uso único fornecido pelo aplicativo e clique em <b>Enviar</b> para concluir o login
                </span>
            </div>
            <Form onSubmit={handleSubmit} method="post">
                <Form.Group className="my-3 mx-5">
                    <Form.Label>
                        Código de uso único:
                    </Form.Label>
                    <input
                        onChange={handleChangeTotp}
                        className={`${style.password} ${error ? style.fieldError : style.fieldSuccess}`}
                        style={{ width: '100%' }}
                        type="text"
                        name="totp"
                        autoComplete="off"
                        placeholder="" required />
                </Form.Group>
                <div className="my-3 mx-5">
                    {error ? <Warnings warning={errorMessage} type="error" /> : <></>}
                </div>
                <div className='d-flex justify-content-center px-5'>
                    <button className={style.submitButton} type="submit" id="submitButton">
                        Enviar
                    </button>
                </div>
            </Form>
            <div className={style.return}>
                <BackToLogin message={'Voltar ao Login'} url='/' />
            </div>
        </div>

    )
}
export default TotpAuth;