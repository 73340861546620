import { Col, Form } from 'react-bootstrap';
import NewPasswordInput from '../../components/NewPasswordInput';
import OnKeyUp from '../../components/OnKeyUp';
import style from '../../components/Form.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { IconContext } from "react-icons";
import { FaExclamationCircle } from 'react-icons/fa';
import { useState, useEffect } from "react";

import { useContext } from "react";
import { AccountContext } from "../../components/Accounts";
import { useNavigate, useParams } from 'react-router-dom';

import { CognitoUser } from "amazon-cognito-identity-js";
import getPool from '../../UserPool';
import { DEFAULT_CLIENT_ID, DEFAULT_USER_POOL_ID } from '../../shared/Config';
import Warnings from '../../components/Warning';
import { navigateToPage } from '../../services/navigateService';
import { PageEnum } from '../../enums/pageEnum';
import { RecoveryPasswordService } from '../../services/RecoveryPasswordService';

interface IFormPassword {
    "password-new": string
    "password-confirm": string
}

function ChangePassword({ method, email }: { method: any, email: any }) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = Object.fromEntries(urlParams.entries());
    let Pool: any = null
    const routeParams = useParams()

    if (params.client_id) {
        Pool = getPool(routeParams.pool_id || "", params.client_id)
    } else {
        Pool = getPool(DEFAULT_USER_POOL_ID, DEFAULT_CLIENT_ID) // default redirect without params
    }
    const getUser = () => {
        return new CognitoUser({
            Username: email.toLowerCase(),
            Pool
        });
    };

    const schema = yup.object({
        "password-new": yup.string().required("A senha não pode estar vazia").min(8, 'Sua senha deve ter no mínimo 8 caracteres').max(16, 'Sua senha deve ter no máximo 16 caracteres').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            ("Sua senha deve conter: \n- 8 a 16 caracteres;\n- Uma letra maiúscula;\n- Uma letra minúscula;\n- Um número;\n- Um caractere especial")
        ),
        "password-confirm": yup.string().required("Confirme sua senha").oneOf([yup.ref('password-new')], 'As senhas devem ser idênticas')
    }).required();

    const [onKey, setOnKey] = useState(<></>);
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [isDisabled, setIsLoginButtonDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const urlUseParams = useParams();


    const { register, trigger, handleSubmit, formState: { errors } } = useForm<IFormPassword>({ resolver: yupResolver(schema) })

    const { getCognitoUser, updatePassword } = useContext(AccountContext);

    const navigate = useNavigate();

    useEffect(() => {
        const user = getCognitoUser()
        if (!user && method === 1) {
            return navigateToPage(navigate, urlUseParams, PageEnum.LOGIN, null, true)
        }

        const key = document.getElementById('formPassword');
        if (key) {
            key.addEventListener("keyup", function (event) {
                if (event.getModifierState("CapsLock")) {
                    setOnKey(<div className={style.warnKey}>
                        <OnKeyUp /></div>
                    )
                } else {
                    setOnKey(<div></div>)
                }
            });
        }
    }, [newPassword, confirm]);

    function handleChange(event: any) {
        setPassword(event.target.value)
    }

    function handleChangeNew(event: any) {
        setNewPassword(event.target.value);
    }

    function handleChangeConfirm(event: any) {
        setConfirm(event.target.value);
    }

    const onSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        // the method sets users first login or resets
        if (method === 1) { // first login method
            updatePassword(newPassword)
                .then(() => {
                    return navigateToPage(navigate, routeParams, PageEnum.LOGIN, params, true)
                })
        }
        else if (method === 2) { // reset password method
            getUser().confirmPassword(code, newPassword, {
                onSuccess: data => {
                    return navigateToPage(navigate, routeParams, PageEnum.LOGIN, null, true)
                },
                onFailure: err => {
                    setError(true);
                    if (err.message === "Password attempts exceeded") {
                        return setErrorMessage("Número de tentativas excedido!");
                    }
                    setErrorMessage("Código de verificação incorreto!");
                }
            })
        } else if (method === 3) { // reset password with recovery email method
            RecoveryPasswordService.confirmPasswordRecovery(code, email, Pool.userPoolId, newPassword, {
                onSuccess: data => {
                    return navigateToPage(navigate, routeParams, PageEnum.LOGIN, null, true)
                },
                onFailure: err => {
                    setError(true);
                    if (err.message === "Password attempts exceeded") {
                        return setErrorMessage("Número de tentativas excedido!");
                    }
                    setErrorMessage("Código de verificação incorreto!");
                }
            })
        }
    };

    return (
        <>
            <div id="formPassword">
                <Form id="passwd-update-form"
                    method="post"
                    onSubmit={onSubmit}
                    onChange={() => trigger(["password-confirm", "password-new"]).then()}>
                    <div>

                        {method === 1 && (
                            <div className="py-2 px-5">
                                <Warnings warning="Atualize sua senha de usuário" type="warning" />
                            </div>
                        )
                        }

                        {(method === 2 || method === 3) && (<>
                            <div className="my-4 mx-5" style={{ textAlign: 'center', lineHeight: '30px' }}>
                                <span>
                                    Digite o código recebido no e-mail cadastrado e em seguida a nova senha escolhida.
                                </span>
                                {/* Insert warning to reset password */}
                            </div>
                            <Form.Group className="my-4 mx-5">
                                <Form.Label>Código de verificação</Form.Label>
                                <Col sm={12}>
                                    <input value={code} onChange={event => setCode(event.target.value)}
                                        className={`${style.password} ${error ? style.fieldError : style.fieldSuccess}`}
                                        style={{ width: '100%' }} />
                                </Col>
                            </Form.Group>
                        </>
                        )}
                    </div>
                    <Form.Group className="my-4 mx-5" controlId="formGroupPassword"
                        onChange={handleChangeNew}>
                        <Form.Label>
                            Nova Senha
                        </Form.Label>
                        <NewPasswordInput register={register} name={"password-new"} id={"password-new"} errors={(errors["password-new"] ? true : false)} />
                        {errors["password-new"] &&
                            <div className={`${style.warnKey} ${style.warnPassword}`}>
                                <IconContext.Provider value={{ color: "red", style: { marginRight: '0.6em', whiteSpace: "pre-wrap" } }}>
                                    <span>
                                        <FaExclamationCircle />
                                    </span>
                                    <span className={style.warnError}>
                                        {errors["password-new"]?.message}
                                    </span>
                                </IconContext.Provider>
                            </div>
                        }
                    </Form.Group>
                    <Form.Group className="mb-4 mx-5" controlId="formGroupPasswordConfirm"
                        onChange={handleChangeConfirm} >
                        <Form.Label>
                            Confirmar Senha
                        </Form.Label>
                        <NewPasswordInput register={register} name={"password-confirm"} errors={(errors["password-confirm"] ? true : false)} id={"password-confirm"} />
                        {errors["password-confirm"] &&
                            <div className={`${style.warnKey} ${style.warnPassword}`} style={{ marginBottom: '0.3em' }}>
                                <IconContext.Provider value={{ color: "red", style: { marginRight: '0.6em', whiteSpace: "pre-wrap" } }}>
                                    <span>
                                        <FaExclamationCircle />
                                    </span>
                                    <span className={style.warnError}>
                                        {errors["password-confirm"]?.message}
                                    </span>
                                </IconContext.Provider>
                            </div>
                        }
                        {onKey}

                        <div style={{ marginBottom: '-1em', marginTop: '1em' }}>
                            {error ? <Warnings warning={errorMessage} type="error" /> : <></>}
                        </div>
                    </Form.Group>
                    <div className='d-flex justify-content-center mb-2 px-5'>
                        <button disabled={errors["password-confirm"] ? true : errors["password-new"] ? true : isDisabled}
                            className={style.submitButton} id="submitButton"
                            type="submit">
                            Enviar
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
} export default ChangePassword;