import axios from "axios"
import { API_BASE_URL } from "../shared/Config"

export const QrcodeService = {
    getQrcode: async function (params: any, urlParams: any) {
        const response = await axios.post(`${API_BASE_URL}/${urlParams.pool_id}/${urlParams.tenant}/qrcode`, params)

        return response
    },

    verifyLoginMethod: async function (params: any, urlParams: any, qrcode: any) {
        const data = {
            qrcode_id: qrcode,
            grant_type: "verify_qrcode_login",
            ...params
        }

        const response = await axios.post(`${API_BASE_URL}/${urlParams.pool_id}/${urlParams.tenant}/token/verifyqrcodelogin`, data)

        return response
    }
}