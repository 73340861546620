export enum PageEnum {
    LOGIN = "",
    UPDATE_PASSWORD = "new-password",
    TOPT = "totp",
    AUTHORIZE = "authorize",
    RESET_PASSWORD = "reset",
    UPDATE_ATTRIBUTES = "update",
    LOGGED_IN = "logged-in",
    SWITCH_ACCOUNT = "switch-account",
    QRCODE_LOGIN = "qrcode-login"
}