import { DEFAULT_S3_URI } from "../../shared/Config";
import placeholderSrc from "../../assets/img/placeholder.webp";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import style from '../../pages/Home/App.module.scss';

const ImgLogo = () => {

    const urlPath = useParams()
    const urlTenant = (urlPath.tenant ? urlPath.tenant : 'mobilemed')
    const [imgLogo, setImgLogo] = useState(`${DEFAULT_S3_URI}${urlTenant}/logo.svg`)

    const fetchImage = async () => {
        const res = await fetch(imgLogo);
        if (!res?.ok) {
            setImgLogo(`${DEFAULT_S3_URI}mobilemed/logo.svg`)
        }
    };

    useEffect(() => {
        fetchImage()
    }, []);

    return (
        <>
            <img src={imgLogo} className={style.imgLogo} />
        </>
    );
};

export default ImgLogo;