import { Outlet, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import style from '../Home/App.module.scss';
import customStyle from 'styled-components';
import { DEFAULT_S3_URI } from "../../shared/Config";
import ImgBackground from "../../components/ImgBackground";
import ImgLogo from "../../components/ImgLogo";

const Layout = () => {

  const urlPath = useParams()
  const urlTenant = (urlPath.tenant ? urlPath.tenant : 'mobilemed')

  const MainCard =
    urlTenant !== 'mobilemed' ? customStyle.div`
    @import url('${DEFAULT_S3_URI}${urlTenant}/custom.css');`
      : customStyle.div``

  const upperCase = urlTenant.charAt(0).toUpperCase();
  const customTitle = urlTenant.slice(1);

  document.title = (urlTenant !== 'mobilemed' ? `Login ${upperCase}${customTitle}` : `Login Mobilemed`)

  return (
    <>
      <div className={`d-flex align-items-center justify-content-center ${style.mainContainer}`}>
        <MainCard className={`${style.AppStyle}`} id="mainCard">
          <div className={`d-md-flex ${style.imgContainer}`} 
          >
            <div className={`d-none d-md-block col-md-6`} >
              <ImgBackground />
            </div>
            <div className={`${style.colMainContent} ${style.formLogin} `}
            >
              <Col 
                style={{ marginTop: '16px', height: 'calc(100% - 40px)' }} 
                className={style.colForm}
               >
                <div className="d-flex justify-content-center col-12">
                  <ImgLogo />
                </div>
                <Outlet />
              </Col>
            </div>
          </div>
        </MainCard>
      </div>
    </>
  );
};

export default Layout;