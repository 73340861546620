import { Context, createContext } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserPool, ClientMetadata } from 'amazon-cognito-identity-js';
import getPool from '../UserPool';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

import { useState } from "react";
import { StorageService } from '../services/StorageService';
import { DEFAULT_CLIENT_ID, DEFAULT_USER_POOL_ID } from '../shared/Config';
import { generateRedirectUri, navigateToPage, navigateToRedirectUri } from '../services/navigateService';
import { PageEnum } from '../enums/pageEnum';
import { IndexedService } from '../services/IndexedService';

const AccountContext: Context<any> = createContext(null);

const Account = (props: any) => {
    const [routeParams, setRouteParams] = useState(useParams())
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlQuery = new URLSearchParams(queryString);
    const params = Object.fromEntries(urlParams.entries());

    let Pool: CognitoUserPool | null = null
    if (params.client_id) {
        Pool = getPool(routeParams.pool_id || "", params.client_id)
    } else {
        Pool = getPool(DEFAULT_USER_POOL_ID, DEFAULT_CLIENT_ID) // default redirect without params
    }

    const [result, setResult] = useState<any>(null);
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
    const [_, setMfaResult] = useState<any>(null);
    const [userInfo, setUserInfo] = useState<any>(null);

    const navigate = useNavigate();

    async function saveTokensAndRedirect(tokens: any, paramsFromComponent: any, email?: any) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const params = Object.fromEntries(urlParams.entries());

        tokens.authorization_code = params.authorization_code // get authorization_code from URL params

        // Send to API storage
        if (!params.authorization_code) {
            return navigateToRedirectUri(params, tokens)
        }

        try {
            const response = await StorageService.getStorage(tokens, paramsFromComponent)
            await IndexedService.setTransactionDB(paramsFromComponent.pool_id, response.data.session, email)

            if(params && params.redirect_uri) {
                const urlString = new URL(params.redirect_uri)
    
                if (urlString.protocol !== 'http:' && urlString.protocol !== 'https:') {
                    let redirect_uri = generateRedirectUri(params)
                    return navigateToPage(navigate, urlQuery, PageEnum.LOGGED_IN, {redirect_uri}, false, urlParams)
                } 
            }

            return navigateToRedirectUri(params)
        } catch (e) {
            return navigateToRedirectUri({
                error: "time_expired",
                error_description: "Time expired, please try again",
                redirect_uri: params.redirect_uri,
            })
        }
    }

    function getCognitoUser() {
        return cognitoUser
    }

    async function getSession() {
        return await new Promise((resolve, reject) => {
            if (cognitoUser) {
                cognitoUser.getSession((err: any, session: any) => {
                    if (err) {
                        reject();
                    } else {
                        resolve(session);
                    }
                });
            } else {
                reject();
            }
        });
    }

    async function totpAuth(totpCode: string, params: any) {
        return await new Promise((resolve, reject) => {
            if (cognitoUser) {
                cognitoUser.sendMFACode(totpCode, {
                    onSuccess: function (session: any) {
                        let expires_in = 300
                    
                        if(session.accessToken && session.accessToken.payload) {
                            expires_in = session.accessToken.payload.exp - session.accessToken.payload.iat
                        }

                        const tokens = {
                            access_token: session.getAccessToken().getJwtToken(),
                            id_token: session.getIdToken().getJwtToken(),
                            refresh_token: session.getRefreshToken().getToken(),
                            expires_in: expires_in,
                        };

                        saveTokensAndRedirect(tokens, params)

                        resolve(cognitoUser)
                    },
                    onFailure: err => {
                        reject(err);
                    },
                }, 'SOFTWARE_TOKEN_MFA');
            } else {
                reject();
            }
        })
    }

    async function authenticate(Username: string, Password: string, poolId: string, clientId: string, routeParamsFromComponent: any) {
        await new Promise((resolve, reject) => {
            const Pool = getPool(poolId, clientId)

            const user: any = new CognitoUser({ Username, Pool });

            const authDetails = new AuthenticationDetails({ Username, Password });

            setCognitoUser(user)
            setResult("")
            setMfaResult("")

            user.authenticateUser(authDetails, {
                onSuccess: function (session: any) {
                    let expires_in = 300

                    if(session.accessToken && session.accessToken.payload) {
                        expires_in = session.accessToken.payload.exp - session.accessToken.payload.iat
                    }

                    const tokens = {
                        access_token: session.getAccessToken().getJwtToken(),
                        id_token: session.getIdToken().getJwtToken(),
                        refresh_token: session.getRefreshToken().getToken(),
                        expires_in: expires_in,
                    };

                    user['tokens'] = tokens;
                    saveTokensAndRedirect(tokens, routeParamsFromComponent, Username)

                    resolve(user); // Resolve user
                },

                totpRequired: (data: any) => {
                    setMfaResult({
                        user: user,
                        userAttr: data
                    })

                    return navigateToPage(navigate, routeParamsFromComponent, PageEnum.TOPT, null, true)
                },

                onFailure: (err: any) => {
                    reject(err);
                },

                newPasswordRequired: (data: any) => {
                    setResult({
                        isFirstLogin: true,
                        user: user,
                        userAttr: data
                    })

                    resolve(data);

                    return navigateToPage(navigate, routeParamsFromComponent, PageEnum.UPDATE_PASSWORD, null, true)
                },
            });

        });
    }

    async function updatePassword(newPassword: string) {
        await new Promise((resolve, reject) => {
            if (!cognitoUser) return reject()

            cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                onSuccess: (r: any) => {
                    resolve(r)
                },
                onFailure: (err: any) => {
                    reject(err)
                }
            });
        });
    }

    function logout() {
        if (!Pool) return

        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
            navigate(0)
        }
    }

    function setInfoUser(data: any) {
        setUserInfo(data) 
    }

    function getInfoUser() {
        return userInfo
    }

    return (
        <AccountContext.Provider value={{
            authenticate,
            getSession,
            logout,
            updatePassword,
            totpAuth,
            getCognitoUser,
            setRouteParams,
            setInfoUser,
            getInfoUser
        }}>
            {props.children}
        </AccountContext.Provider>
    );
};

export { Account, AccountContext };