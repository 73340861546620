import BackToLogin from "../../components/BackToLogin";
import Warnings from "../../components/Warning";
import style from '../../components/Form.module.scss'

export default function NotFound() {

    return (<>
    <div style={{rowGap: '250px', flexDirection: 'column', display: 'flex', flex:'1 0'}}>
        <div style={{ margin: '1em 2rem 0em' }}>
            <Warnings warning={'404 Página não encontrada'} type="error" />
        </div>
        <div className={style.return} >
            <BackToLogin url="../" message="Voltar à página de Login" />
        </div>
    </div>
    </>)
}