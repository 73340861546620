import { DEFAULT_S3_URI } from "../../shared/Config";
import placeholderSrc from "../../assets/img/placeholder.webp";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ImgBackground = () => {

    const [imgFundoSrc, setImgFundoSrc] = useState(placeholderSrc);

    const urlPath = useParams()
    const urlTenant = (urlPath.tenant ? urlPath.tenant : 'mobilemed')
    const [imgFundo, setImgFundo] = useState(`${DEFAULT_S3_URI}${urlTenant}/fundo.webp`)

    const fetchImage = async () => {
        const r = await fetch(imgFundo)
        if (!r?.ok) {
            setImgFundo(`${DEFAULT_S3_URI}mobilemed/fundo.webp`)
        }
    };

    useEffect(() => {
        fetchImage()
    }, []);

    const imgLoadingClass =
        placeholderSrc && imgFundoSrc === placeholderSrc ? "imgLoading" : "imgLoaded";

    return (
        <>
            <img src={imgFundo} className={`img-fluid`} style={{ maxHeight: 1000, borderRadius: 10 }} loading="lazy" />
        </>
    );
};

export default ImgBackground;