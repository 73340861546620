import { IconContext } from "react-icons";
import { FaExclamationCircle } from 'react-icons/fa';
import { FaExclamationTriangle } from "react-icons/fa";
import { FaCheckSquare } from "react-icons/fa";
import style from './WarnUpdate.module.scss';

function Warnings({ warning, type }: { warning: string, type: string }) {
    return (
        <>

            {type === "error" &&
                (
                    
                    <div className={style.warn}>
                    <div className={style.errorUser}>
                        <IconContext.Provider value={{ color: "red",
                            style: { marginRight: '0.6em', minHeight: '1.2em', paddingBottom: '0.2em', paddingTop:'0.1em' } 
                          }}>
                            <span>
                                <FaExclamationCircle />
                            </span>
                            <span dangerouslySetInnerHTML={{
                                "__html": warning,
                            }} />
                        </IconContext.Provider>
                    </div>
                    </div>
                )}

            {type === "warning" &&
                (<div className={style.warn}>
                    <div className={style.warnUser}>
                        <IconContext.Provider value={{ color: "orange", style: { marginRight: '0.6em' } }}>
                            <span>
                                <FaExclamationTriangle />
                            </span>
                            <span dangerouslySetInnerHTML={{
                                "__html": warning,
                            }} />
                        </IconContext.Provider>
                    </div>
                </div>)}

            {type === "success" &&
                (<div className={style.warn}>
                    <div className={style.successUser}>
                        <IconContext.Provider value={{ color: "green", style: { marginRight: '0.6em' } }}>
                            <span>
                                <FaCheckSquare />
                            </span>
                            <span dangerouslySetInnerHTML={{
                                "__html": warning,
                            }} />
                        </IconContext.Provider>
                    </div>
                </div>)}
        </>
    )
}

export default Warnings;