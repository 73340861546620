import { NavigateFunction } from "react-router-dom";
import { PageEnum } from "../enums/pageEnum";
import { DEFAULT_REDIRECT_URI, DEFAULT_TENANT, DEFAULT_USER_POOL_ID } from "../shared/Config";
import { RedirectService } from "./RedirectService";

function getDefaultPath() {
    return `../../../../${DEFAULT_USER_POOL_ID}/${DEFAULT_TENANT}`;
}

export function navigateToDefaultPage(navigation: NavigateFunction) {
    return navigation(getDefaultPath());
}

export async function navigateToDefaultRedirect(params: any) {
    try {
        const redirect = await RedirectService.getTenantRedirect(params)

        if (redirect.data.redirect_uri) {
            return window.location.replace(redirect.data.redirect_uri)
        }

        return window.location.replace(DEFAULT_REDIRECT_URI)
    } catch (error) {
        return window.location.replace(DEFAULT_REDIRECT_URI)
    }
}

export function navigateToPage(navigation: NavigateFunction, urlParams: any, Page: PageEnum, params: any = null, keepParams: boolean = false, urlParamsDesktop?: any) {
    let url = `../../../../${urlParams.pool_id || urlParamsDesktop.pool_id || DEFAULT_USER_POOL_ID}/${urlParams.tenant || urlParamsDesktop.tenant || DEFAULT_TENANT}/${Page}`;

    if (!params && !keepParams) {
        return navigation(url);
    }

    if (params) {
        const urlParams = new URLSearchParams();

        for (const key in params) {
            let value = params[key];
            urlParams.set(key, value);
        }

        url += `?${urlParams.toString()}`;
        return navigation(url);
    }

    if (keepParams) {
        const urlParams = new URLSearchParams(window.location.search);
        url += `?${urlParams.toString()}`;
        return navigation(url);
    }
}

export function generateRedirectUri(params: any = null, tokens: any = null) {
    const urlResponse = new URLSearchParams();

    if (params && params.authorization_code) {
        urlResponse.set('code', params.authorization_code)
        urlResponse.set('state', params.state)
    } else if (tokens && tokens.access_token) {
        urlResponse.set('access_token', tokens.access_token)
        urlResponse.set('refresh_token', tokens.refresh_token)
        urlResponse.set('id_token', tokens.id_token)
    } else if (params && params.error) {
        urlResponse.set('error', params.error)
        if (params.error_description) urlResponse.set('error_description', params.error_description)
    }

    let url_redirect = params.redirect_uri || DEFAULT_REDIRECT_URI;
    const urlParams = new URLSearchParams(url_redirect.split('?')[1]);

    urlResponse.forEach((value, key) => {
        if (urlParams.has(key)) {
            if (urlParams.get(key) !== value) {
                urlParams.set(key, value)
            }
        } else {
            urlParams.set(key, value)
        }
    });

    url_redirect = url_redirect.split('?')[0] + '?' + urlParams.toString()

    return url_redirect
}

export function navigateToRedirectUri(params: any = null, tokens: any = null) {

    let url_redirect = generateRedirectUri(params, tokens)

    if (window.opener) {
        window.opener.location.href = url_redirect
        return window.close()
    }

    window.open(url_redirect, '_self')
}