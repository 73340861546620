import { Col } from 'react-bootstrap';
import Warnings from "../../components/Warning";
import { useEffect } from 'react';
import { navigateToPage } from '../../services/navigateService';
import { useNavigate, useParams } from 'react-router-dom';
import { PageEnum } from '../../enums/pageEnum';

export default function LoggedIn() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = Object.fromEntries(urlParams.entries());

    const redirect_uri = params.redirect_uri

    const navigate = useNavigate();

    const urlUseParams: any = useParams();

    useEffect(() => {
        if (!redirect_uri?.includes('?')) {
            return navigateToPage(navigate, urlUseParams, PageEnum.LOGIN, null, true)
        }

        setTimeout(() => {
            window.open(redirect_uri, '_self')
        }, 1000)
    })

    return (
        <Col>
            <div style={{ padding: '0em 2em' }} >
                <Warnings warning={'Login efetuado com sucesso! Você já pode fechar essa aba.'} type="success" />
                <div style={{ marginTop: '6em', padding: '2em 2em 1em 2em', textAlign: 'center', fontSize: '14px' }}>
                    <span>
                        Caso o login não tenha sido efetuado,
                        <a href={redirect_uri} style={{marginLeft: 4}}>
                            clique aqui.
                        </a>
                    </span>
                </div>
            </div>
        </Col>
    )
}