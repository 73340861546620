import axios from "axios"
import { API_BASE_URL } from "../shared/Config";
import { IndexedService } from "./IndexedService";

export const AuthorizationService = {
    getAuthorization: async function (params: any, urlParams: any) {
        try {
            let headers: any = {}
            let session = await IndexedService.getTransactionDB(urlParams.pool_id);

            if (session) {
                if (session.length === 1) {
                    headers['x-mobilemed-session'] = session
                }

                if (session.length > 1) {
                    for (let i = 0; i < session.length; i++) {
                        headers['x-mobilemed-session'] = session.join(',')
                    }

                }
            }
            const response = await axios.put(`${API_BASE_URL}/${urlParams.pool_id}/${urlParams.tenant}/authorize`, params, { headers })
            return response
        } catch (err) {
            return err
        }
    },

    getNewAuthorization: async function (params: any, urlParams: any, session: any) {
        let headers: any = {}
        headers['x-mobilemed-session'] = session

        if (!params.redirect_uri.includes('http:') && !params.redirect_uri.includes('https:')) {
            if (params.redirect_uri.includes('?code')) {
                params.redirect_uri = params.redirect_uri.split('?code')[0];
            }
        }

        const response = await axios.put(`${API_BASE_URL}/${urlParams.pool_id}/${urlParams.tenant}/authorize`, params, { headers })
        return response
    }
}