import { Form } from 'react-bootstrap';
import style from '../../components/Form.module.scss';
import { useState } from "react";
import BackToLogin from '../../components/BackToLogin';

import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import ChangePassword from '../ChangePassword';
import { useParams } from 'react-router-dom';
import { DEFAULT_CLIENT_ID, DEFAULT_USER_POOL_ID } from '../../shared/Config';
import getPool from '../../UserPool';
import RecoveryEmailPassword from '../RecoveryEmailPassword';
import { RecoveryPasswordService } from '../../services/RecoveryPasswordService';


function ResetPassword() {
    const [stage, setStage] = useState(1); // 1 = set email stage, 2 = set code stage // 3 = set email options stage
    const [email, setEmail] = useState("");
    const [recoveryEmail, setRecoveryEmail] = useState("")

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params = Object.fromEntries(urlParams.entries());
    let Pool: any = null
    const routeParams = useParams()

    if (params.client_id) {
        Pool = getPool(routeParams.pool_id || "", params.client_id)
    } else {
        Pool = getPool(DEFAULT_USER_POOL_ID, DEFAULT_CLIENT_ID) // default redirect without params
    }
    const getUser = () => {
        return new CognitoUser({
            Username: email.toLowerCase(),
            Pool
        });
    };

    const [isDisabled, setIsLoginButtonDisabled] = useState(false)

    const verifyEmail = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        RecoveryPasswordService.getRecoveryEmail({ email: email, poolId: Pool.userPoolId }).then((res) => {
            setRecoveryEmail(res.data.recovery_email)
            if (!res.data.recovery_email) {
                getUser().forgotPassword({
                    onSuccess: (_: any) => {
                    },
                    onFailure: (_: any) => {
                    },
                    inputVerificationCode: (_: any) => {
                        setStage(2);
                    }
                });
            } else {
                setStage(3);
            }
        })

    }

    return (
        <>
            {stage === 1 && (
                <>
                    <div style={{ padding: '1.5em 3em 0em' }}>
                        <p style={{ textAlign: 'center', lineHeight: '30px' }}>
                            Digite o seu e-mail e enviaremos um e-mail
                            com instruções para redefinir sua senha.
                        </p>
                    </div>
                    <Form onSubmit={verifyEmail}
                        method="post"
                        className='col-12'
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}
                    >
                        <Form.Group
                            className="col-9"
                            controlId="formGroupEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control type="email" placeholder="" required name="username" className={style.password}
                                value={email} onChange={event => setEmail(event.target.value)}
                            />
                        </Form.Group>
                        <div className='d-flex justify-content-center mb-4 px-5'>
                            <button name='btnSubmit'
                                disabled={isDisabled}
                                className={style.submitButton}
                                id="submitButton"
                                type="submit">
                                Enviar
                            </button>
                        </div>
                    </Form>
                    <div className={style.return} style={{ padding: '0em 0em 0em 1.5em' }}>
                        <BackToLogin message='Voltar ao Login' url='/' />
                    </div>
                </>
            )}

            {stage === 2 && (
                <>
                    <ChangePassword method={2} email={email} />
                </>
            )}

            {stage === 3 && (
                <>
                    <RecoveryEmailPassword email={email} recoveryEmail={recoveryEmail} />
                </>
            )}
        </>
    )
} export default ResetPassword;