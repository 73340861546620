import { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router";
import { AuthorizationService } from "../../services/AuthorizeService";
import Warnings from "../../components/Warning";
import { generateRedirectUri, navigateToPage, navigateToRedirectUri } from "../../services/navigateService";
import { PageEnum } from "../../enums/pageEnum";
import { AccountContext } from "../../components/Accounts";
import { IndexedService } from "../../services/IndexedService";

let respAuthorize = false;

interface AccountData {
    authorization_code: string;
    client_id: string;
    email?: string;
    name?: string;
    picture?: string;
    redirect_uri: string;
    session: string;
    state: string;
    success: boolean;
}

export default function Authorization() {
    const navigate = useNavigate();
    const urlparams: any = useParams();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState<any>(null);
    const { setInfoUser } = useContext(AccountContext);

    useEffect(() => {
        if (!respAuthorize) {
            respAuthorize = true;
            const queryString = window.location.search;
            const urlQuery = new URLSearchParams(queryString);
            const params = Object.fromEntries(urlQuery.entries());

            sessionStorage.setItem('codeChallenge', params.code_challenge);
            sessionStorage.setItem('codeMethod', params.code_challenge_method);

            AuthorizationService.getAuthorization(params, urlparams).then(async (response: any) => {
                let data = response.data
                setInfoUser(data)

                if (Array.isArray(data) && data.length > 1) {
                    data = data.filter((account: AccountData) => account.email);

                    if (data.length === 0) {
                        respAuthorize = true;
                        const newData = response.data.at(-1)
                        return navigateToPage(navigate, urlparams, PageEnum.LOGIN, newData)
                    }

                    data = data.at(-1);
                }

                if (params.response_type !== 'code') {
                    throw new Error('response_type invalid')
                }

                if (params.login_hint) {
                    data.login_hint = params.login_hint
                }

                if (data && data.success) {
                    params.authorization_code = data.authorization_code
                    IndexedService.setTransactionDB(urlparams.pool_id, data.session, data.email)

                    if (params && params.redirect_uri) {
                        const urlString = new URL(params.redirect_uri)

                        if (urlString.protocol !== 'http:' && urlString.protocol !== 'https:') {
                            data.redirect_uri = generateRedirectUri(params)
                            return navigateToPage(navigate, urlQuery, PageEnum.SWITCH_ACCOUNT, data, false, urlparams)
                        }
                    }

                    return navigateToPage(navigate, urlparams, PageEnum.SWITCH_ACCOUNT, data, true)
                }

                // IndexedService.removeTransactionDB(urlparams.pool_id)
                respAuthorize = true;
                return navigateToPage(navigate, urlparams, PageEnum.LOGIN, data)
            }).catch((error: any) => {
                if (error.message === 'response_type invalid') {
                    setMessage('response_type está inválido...')
                } else {
                    setMessage('redirect_uri ou client_id está inválido...')
                }
                setError(true)
                navigateToRedirectUri(params)
                return
            })
        }
    }, []);

    return (
        (
            error ?
                <div className="col-12">
                    <div className="m-5">
                        <Warnings warning={message} type="error" />
                    </div>
                </div> :
                <div className="col-12">
                    <div className="m-5">
                        <Warnings warning="Redirecionando para o login..." type="success" />
                    </div>
                </div>
        )
    )
}