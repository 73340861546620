import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { DEFAULT_CLIENT_ID, DEFAULT_USER_POOL_ID } from './shared/Config';


function getUserPool(user_pool_id: string, client_id: string) {
  const poolData = {
    UserPoolId: user_pool_id || DEFAULT_USER_POOL_ID,
    ClientId: client_id || DEFAULT_CLIENT_ID
  };

  return new CognitoUserPool(poolData);
}

export default getUserPool