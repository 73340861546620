import { FaArrowLeft } from 'react-icons/fa';
import style from '../Form.module.scss';

function BackToLogin({ message, url }: { message: string, url: string }) {

    message = message.replace(/&laquo|;|&raquo/g, '');

    return (
        <>
            <a
                // href={url}
                href="javascript:history.back()"
                className='d-flex justify-content-start align-items-center mt-4 mb-3' id="backToLogin">
                <FaArrowLeft style={{ marginRight: '0.4em' }} />
                <span dangerouslySetInnerHTML={{
                    "__html": message,
                }} />
            </a>
        </>
    )
}

export default BackToLogin;