import { IconContext } from "react-icons";
import { FaExclamationCircle } from 'react-icons/fa';
import style from '../Form.module.scss';

function OnKeyUp(){
    
    const styled = { marginRight: '0.6em', minHeight: '1.3em', paddingBottom: '0.3em', paddingTop:'0.1em' };

    return(
        <>
        <div className='mt-1'>
            <IconContext.Provider value={{ color: "red" }}>
                <span>
                    <FaExclamationCircle style={styled}/> 
                </span>
                <span className={style.warnError}>
                    Caps Lock ativado
                </span>
            </IconContext.Provider>
        </div>
        </>
    )
}

export default OnKeyUp;