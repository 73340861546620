let database: IDBDatabase;

export const IndexedService = {
    indexedDBSupport: function () {
        return 'indexedDB' in window
    },

    createIndexedDatabase: async function (): Promise<IDBDatabase> {
        return await new Promise((resolve, reject) => {
            if (!IndexedService.indexedDBSupport())
                return

            const request = window.indexedDB.open('indexedDB', 2);

            request.onsuccess = () => {
                database = request.result
                resolve(database);
            };

            request.onerror = () => {
                reject(request.error)
            };

            request.onupgradeneeded = () => {
                try {
                    const db = request.result;
                    if (db.objectStoreNames.contains('sessions'))
                        db.deleteObjectStore('sessions');
                    db.createObjectStore('sessions', { keyPath: 'poolId' });
                } catch (error) {
                    return error;
                }
            };
        });
    },

    setTransactionDB: async function (
        poolId: string,
        sessionToken: string,
        email: string
    ) {
        if (!database)
            await IndexedService.createIndexedDatabase();

        let transaction = database.transaction('sessions', "readwrite");

        transaction.onerror = () => {
            throw new Error('erro na transaction')
        };

        let store = transaction.objectStore('sessions');
        let getStore = store.get(`${poolId}:session`);

        getStore.onsuccess = () => {
            let sessionInfo = getStore.result?.sessionInfo || [];
            const index = sessionInfo.findIndex((item: { email: string; }) => item.email === email);

            if (index !== -1) {
                sessionInfo.splice(index, 1);
            }

            sessionInfo.push({ email, sessionToken });

            const updateRequest = store.put({ poolId: `${poolId}:session`, sessionInfo });

            return updateRequest
        }
    },

    getTransactionDB: async function (poolId: string): Promise<any> {
        if (!database)
            await IndexedService.createIndexedDatabase();

        return await new Promise((resolve, reject) => {
            let transaction = database.transaction('sessions')
            let store = transaction.objectStore('sessions');
            let getStore = store.get(`${poolId}:session`)

            getStore.onerror = () => {
                resolve(null)
            }

            getStore.onsuccess = async () => {
                if (getStore.result) {
                    let tokens = getStore.result.sessionInfo.map((session: { email: string, sessionToken: string}) => session.sessionToken)
                    resolve(tokens)
                }
                resolve(null)
            }
        })
    },

    removeTransactionDB: async function (poolId: string) {
        if (!database)
            await IndexedService.createIndexedDatabase();

        let transaction = database.transaction('sessions')
        let store = transaction.objectStore('sessions');
        store.delete(`${poolId}:session`);
    },
}