import { VscEye } from 'react-icons/vsc';
import { VscEyeClosed } from 'react-icons/vsc';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import style from '../Form.module.scss';

function PasswordInput(this: any, { errors, id, value, handleChange }: { errors: boolean, id: string , value: any, handleChange:any}) {
    let icon
    const [show, setShow] = useState(true);
    const [type, setType] = useState('password');
    const handleClick = () => {
        setShow(show => !show)
        setType(show ? 'text' : 'password')
    }
    if (show) {
        icon = <VscEye style={{
            paddingBottom: '0.02em'
        }}></VscEye>
    }
    else {
        icon = <VscEyeClosed style={{
            paddingTop: '0.02em'
        }}></VscEyeClosed>
    }

    return (
        <>
            <Col sm={12}>
                <div className={`${style.password} ${errors ? style.fieldError : style.fieldSuccess}`}>
                    <input value={value} 
                    onChange={handleChange}
                    style={{ width: '90%' }} id={id} name={id} 
                    // {...register(value)}
                     type={type} required/>
                    <button type="button" className='pl-0 pr-2' onClick={() => handleClick()}> {icon}
                    </button>
                </div>
            </Col>
        </>
    )
}

export default PasswordInput;